import React, { useState, useEffect, useRef } from "react";
import PreviewGuide from "../PreviewGuide";
import getJSONfromPublic from "../getJSONfromPublic"; // Adjust this path to where your getJSONfromPublic function is located
import { RainbowText, RainbowButton, RainbowButton1 } from "./Rainbow";
import { useNavigate } from "react-router-dom";
import { MdOutlineAutoAwesome } from "react-icons/md";
import useOnScreen from "../useOnScreen"; // Adjust the import path to where your useOnScreen hook is located
import { Link } from "react-router-dom";

// Updated words and descriptions to match the new marketing message
const actionWords = ["Revolutionize", "Elevate"];
const actionDescriptions = [
  "Effortlessly Create How-To Video Masterpiece with Generative AI",
  "Collaborate and Share Videos with Ease in Landsape and Portrait Mode",
  "10X Your Digital Adoption Video Productivity",
];

const actionDescriptions1 = [
  "Revolutionize Digital Adoption GIF Creation with Generative AI",
  "Elevate your GIF creation process with the magic of Generative AI.",
  "10X Your Digital Adoption GIF Productivity",
];

const actionDescriptions2 = [
  "Revolutionize Digital Adoption Google/Microsoft Slide Creation with the Generative AI",
  "Elevate your Google Slide and Powerpoint Digital Adoption presentations with the power of Generative AI.",
  "Accelerate your Digital Adoption Slide creation process, making it 10X faster and more innovative.",
];
// Utilize Tailwind CSS for styling and animation
const tailwindBlink = "animate-ping";

function FeaturedGuide() {
  // Create refs for your lazy-loaded components
  const FBRef = useRef();
  const llmRef = useRef();
  const guideRef = useRef();
  const howtoRef = useRef();
  const chatGPTRef = useRef();
  const portraitRef = useRef();
  const docRef = useRef();

  // Use the custom hook to check if the elements are in the viewport
  const isFBVisible = useOnScreen(FBRef);
  const isLlmVisible = useOnScreen(llmRef);
  const isGuideVisible = useOnScreen(guideRef);
  const isHowtoVisible = useOnScreen(howtoRef);
  const isChatGPTVisible = useOnScreen(chatGPTRef);
  const isPortraitVisible = useOnScreen(portraitRef);
  const isDocVisible = useOnScreen(docRef);

  const [persistVisibility, setPersistVisibility] = useState({
    FB: false,
    llm: false,
    guide: false,
    howto: false,
    chatGPT: false,
    portrait: false,
    doc: false,
  });

  // Updated visibility logic with persisting behavior
  useEffect(() => {
    const updateVisibility = (isVisible, key) => {
      if (isVisible && !persistVisibility[key]) {
        setPersistVisibility((prev) => ({ ...prev, [key]: true }));
      }
    };

    updateVisibility(isFBVisible, "FB");
    updateVisibility(isLlmVisible, "llm");
    updateVisibility(isGuideVisible, "guide");
    updateVisibility(isHowtoVisible, "howto");
    updateVisibility(isChatGPTVisible, "chatGPT");
    updateVisibility(isPortraitVisible, "portrait");
    updateVisibility(isDocVisible, "doc");
  }, [
    isFBVisible,
    isLlmVisible,
    isGuideVisible,
    isHowtoVisible,
    isChatGPTVisible,
    isPortraitVisible,
    isDocVisible,
  ]);

  const navigate = useNavigate();
  const [actionIndex, setActionIndex] = useState(0);
  const [guideData, setGuideData] = useState(null); // State to hold fetched guide data
  const [howtoData, setHowToData] = useState(null); // State to hold fetched guide data
  const [chatGPT, setChatGPT] = useState(null); // State to hold fetched guide data
  const [portraitData, setPortraitData] = useState(null); // State to hold fetched guide data
  const [docData, setDocData] = useState(null); // State to hold fetched guide data
  const [llmData, setLlmData] = useState(null); // State to hold fetched guide data
  const [FBData, setFBData] = useState(null); // State to hold fetched guide data
  const [contentTypeIndex, setContentTypeIndex] = useState(0); // State for cycling content types
  const contentTypes = ["Videos", "GIF", "Doc", "Slide"];
  const currentContentType = contentTypes[contentTypeIndex]; // Current content type (Videos, GIF, Doc, Slide)

  useEffect(() => {
    const contentTypeInterval = setInterval(() => {
      setContentTypeIndex((prevIndex) => (prevIndex + 1) % contentTypes.length);
    }, 2000); // Change the content type every 2 seconds
    return () => clearInterval(contentTypeInterval);
  }, []);

  // git wVdZ4zMt1PYD6mmNflk6ZApiZEe2
  // wag YcHmlVjlgaMTJldkZ3I2cyuaEj82

  useEffect(() => {
    const fetchGuideData = async () => {
      // Define your UID and session IDs for each type of guide
      const guideSessionId = "recording_1710801416482_510852"; // Replace with your actual session ID
      const howtoSessionId = "recording_1709971042833_7218fbbdad965"; // Replace with your actual session ID
      const chatGPTSessionId = "recording_1710269452137_7b3f2a88975b4"; // Replace with your actual session ID
      const portraitVideoSessionId = "recording_1711386443960_556407"; // Replace with your actual session ID
      const docId = "recording_1711867514055_2e6af5342e498"; // Replace with your actual session ID
      const LLMId = "recording_1715028107857_69e3286ae4754"; // Replace with your actual session ID
      const FBId = "recording_1714859084370_867432"; // Replace with your actual session ID

      try {
        const FBData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          FBId
        );

        const LLMData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          LLMId
        );
        const guideData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          guideSessionId
        );
        const howtoData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          howtoSessionId
        );
        const chatGPTData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          chatGPTSessionId
        );

        const portraitData = await getJSONfromPublic(
          "YcHmlVjlgaMTJldkZ3I2cyuaEj82",
          portraitVideoSessionId
        );

        const docData = await getJSONfromPublic(
          "YcHmlVjlgaMTJldkZ3I2cyuaEj82",
          docId
        );
        if (FBData)
          setFBData({
            ...FBData,
            guideTitle: "Featured AI Guide: " + FBData.guideTitle,
          });
        if (LLMData)
          setLlmData({
            ...LLMData,
            guideTitle: "Featured Generative Guide: " + LLMData.guideTitle,
          });

        if (guideData)
          setGuideData({
            ...guideData,
            guideTitle: "Featured Guide: " + guideData.guideTitle,
          });
        if (howtoData)
          setHowToData({
            ...howtoData,
            guideTitle: "Featured Video: " + howtoData.guideTitle,
          });
        if (chatGPTData)
          setChatGPT({
            ...chatGPTData,
            guideTitle: "Featured GIF: " + chatGPTData.guideTitle,
          });
        if (portraitData)
          setPortraitData({
            ...portraitData,
            guideTitle: "Featured Portrait Video: " + portraitData.guideTitle,
          });
        if (docData)
          setDocData({
            ...docData,
            guideTitle: "Featured Guide:  " + docData.guideTitle,
          });
        // console.log(portraitData);
      } catch (error) {
        console.error("There was a problem fetching guide data:", error);
      }
    };

    fetchGuideData();

    const wordChangeInterval = setInterval(() => {
      setActionIndex((prevIndex) => (prevIndex + 1) % actionWords.length);
    }, 4000); // Change the word every 4 seconds
    return () => clearInterval(wordChangeInterval);
  }, []);

  const currentDescription1 = actionDescriptions1[actionIndex];
  const currentDescription2 = actionDescriptions2[actionIndex];

  const handleButtonClick = () => {
    window.open(
      "https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne?hl=en&authuser=0&pli=1",
      "_blank"
    );
  };

  return (
    <>
      <section className="bg-gradient-to-r from-blue-50 to-gray-100 py-6 px-6 rounded">
        <div className="bg-gradient-to-r from-teal-400 to-teal-900 py-8 px-6 rounded">
          <div className="flex justify-center mb-2">
            <h2 className="text-3xl font-bold text-teal-200 mb-8">
              AI Tools for Creation and Marketing of Video Tutorials
            </h2>
          </div>

          <div className="flex justify-center">
            <h3 className="text-xl font-semibold text-teal-100">
              For YouTubers, Educators and How-To Documentation Writers
            </h3>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2 lg:grid-cols-2 place-items-center w-full">
          {/* Card for Browser Extension */}
          <div className="w-full p-4 bg-white border border-gray-300 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col justify-between h-full">
            {/* Centered Heading */}
            <MdOutlineAutoAwesome className="text-blue-500 text-3xl self-center mb-4" />
            <div className="flex flex-col items-center">
              <h3 className="text-xl font-semibold text-gray-900 text-center">
                Web Apps: Install Chrome Extension
              </h3>
            </div>
            <p className="text-gray-700 text-lg mb-4 leading-relaxed text-center mt-2 mb-2">
              Supercharge your How-To Video workflow with AI-powered extension.
            </p>
            {/* Points */}
            <div className="space-y-2 mb-4">
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Create How-To videos effortlessly
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Capture screens step-by-step
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Boost productivity with automation
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Integrates with the Web Editor
              </div>
            </div>
            <div className="mt-auto">
              <RainbowButton1 className="w-full text-sm text-white bg-blue-500 hover:bg-blue-600 py-2 rounded">
                Install
              </RainbowButton1>
            </div>
          </div>

          {/* Card for Web App */}
          <div className="w-full p-4 bg-white border border-gray-300 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col justify-between h-full">
            {/* Centered Heading */}
            <MdOutlineAutoAwesome className="text-blue-500 text-3xl self-center mb-4" />
            <div className="flex flex-col items-center">
              <h3 className="text-xl font-semibold text-gray-900 text-center">
                Mobile Apps/Slides: Upload Images
              </h3>
            </div>
            <p className="text-gray-700 text-lg mb-4 leading-relaxed text-center mt-2 mb-2">
              Create mobile guides effortlessly by uploading screenshots or
              photos.
            </p>
            {/* Points */}
            <div className="space-y-2 mb-4">
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Convert slides into videos with AI voice-overs
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Upload images and generate guides
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Works with mobile app screenshots
              </div>
              <div className="bg-blue-50 p-4 rounded-lg border border-gray-200">
                Create tutorials in minutes
              </div>
            </div>
            <div className="mt-auto">
              <Link to="/guide">
                <RainbowButton1 className="w-full text-sm text-white bg-blue-500 hover:bg-blue-600 py-2 rounded">
                  Web Editor
                </RainbowButton1>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex justify-center mb-2 mt-10">
          <h2 className="text-3xl font-bold text-gray-400 mb-8">
            Rapidly Create, Market and Monetize Videos
          </h2>
        </div>
      </section>
    </>
  );
}

export default FeaturedGuide;
