import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
  useRef,
  useContext,
} from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Login from "./login";
import { FaSpinner } from "react-icons/fa";
import { auth } from "./firebase";
import { signOut } from "firebase/auth";
import { Helmet } from "react-helmet";
import CatchAll from "./CatchAll";
import { AuthContext } from "./AuthProvider";
import {
  useParams,
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import HomeLayout from "./HomeLayout";
import GoRedirect from "./GoRedirect"; // Import the GoRedirect component
import "./App.css";
import AnalyticsLogger from "./AnalyticsLogger";
// import BannerCreator from "./BannerCreator";

// Utility function to parse query parameters
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  const [showLogin, setShowLogin] = useState(false);
  const [loginNotification, setLoginNotification] = useState(false);
  const [notificationShown, setNotificationShown] = useState(false);
  const [logoutNotification, setLogoutNotification] = useState(false);

  const QRCodeGenerator = React.lazy(() => import("./QRCodeGenerator"));
  const ThumbnailCreator = React.lazy(() => import("./ThumbnailCreator"));
  // const LogoCreator = React.lazy(() => import("./LogoCreator"));
  const Admin = React.lazy(() => import("./AdminPage"));
  // const Banner = React.lazy(() => import("./BannerCreator"));
  const Food = React.lazy(() => import("./Food"));

  const GoForm = React.lazy(() => import("./components/GoForm"));
  const GoList = React.lazy(() => import("./components/GoList"));
  const Go = React.lazy(() => import("./Go"));
  const OKR = React.lazy(() => import("./OKR"));
  const VideoCreate = React.lazy(() => import("./VideoCreate"));
  const Guide = React.lazy(() => import("./Guide"));
  const GuideMD = React.lazy(() => import("./GuideMD"));
  const MyGuides = React.lazy(() => import("./MyGuide"));
  const Course = React.lazy(() => import("./Course"));
  const My = React.lazy(() => import("./My"));
  const TutorialCreate = React.lazy(() => import("./TutorialCreate"));
  const LectureCreate = React.lazy(() => import("./LectureCreate"));
  const Quiz = React.lazy(() => import("./QuizCreate"));
  const Family = React.lazy(() => import("./Family"));
  const ShowPublicQuiz = React.lazy(() =>
    import("./components/quiz/showPublicQuiz")
  );
  const MyQuiz = React.lazy(() => import("./components/quiz/myQuiz"));
  const DreamLayout = React.lazy(() => import("./DreamLayout"));
  const SubscribePage = React.lazy(() => import("./SubscribePage"));
  const PrivacyLayout = React.lazy(() => import("./PrivacyLayout"));
  const ShowVideo = React.lazy(() => import("./components/ShowGuide"));
  const renderCount = useRef(0);
  const authContextValue = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const isVideoEmbed =
    window.location.pathname.includes("/video") &&
    new URLSearchParams(window.location.search).get("embed") === "true";

  // Check if the current URL contains /guide?id= or /guide using window.location
  const shouldShowFooter =
    !window.location.pathname.includes("/guide") ||
    (window.location.pathname.includes("/guide") &&
      !window.location.search.includes("id="));

  // Show login notification
  useEffect(() => {
    if (user && !notificationShown) {
      setLoginNotification(true);
      setNotificationShown(true);
      setTimeout(() => {
        setLoginNotification(false);
      }, 3000); // Hide after 3 seconds
    }
  }, [user, notificationShown]);

  // Handle logout and show notification
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setLogoutNotification(true);
        setTimeout(() => {
          setLogoutNotification(false);
          window.location.reload(); // Refresh the page after signout
        }, 3000); // Notification duration before reload
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const closeLogin = () => {
    setShowLogin(false);
  };

  function capitalize(text) {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  function DynamicMetaTags() {
    const location = useLocation();
    const [title, setTitle] = useState("Kimavi | Made Easy");
    const [description, setDescription] = useState(
      "AI Tools for Creation and Marketing of Video Tutorials! Explore our Library of Video Tutorials, Create your own Video Tutorials, Market your Video Tutorials!."
    );

    useEffect(() => {
      const segments = location.pathname
        .split("/")
        .filter((segment) => segment);

      let newTitle;
      let newDescription;

      if (location.pathname.includes("/tc")) {
        newTitle = "Thumbnail Creation - Made Easy";
        newDescription =
          "Create beautiful thumbnails effortlessly with our easy-to-use thumbnail creation tool!";
      } else if (location.pathname.includes("/qr")) {
        newTitle = "QR Code Creation - Made Easy";
        newDescription =
          "Generate QR codes quickly and easily for all your needs with our QR Code Creation tool!";
      } else if (segments[0] === "family" && segments.length >= 1) {
        const treeName = segments[1];
        newTitle = `Family Tree of ${capitalize(treeName)}`;
        newDescription = `Discover the Family Tree of ${capitalize(
          treeName
        )}. Explore your heritage and uncover the stories of your ancestors.`;
      } else {
        newTitle = "Kimavi | Made Easy";
        let baseDescription =
          "AI Tools for Creation and Marketing of Video Tutorials! Explore our Library of Video Tutorials, Create your own Video Tutorials, Market your Video Tutorials!";

        if (segments.length) {
          newTitle +=
            " - " +
            segments
              .map((segment) => capitalize(segment.replace(/-/g, " ")))
              .join(" - ");
        }

        newDescription = `${baseDescription} Dive deeper into ${newTitle}.`;

        const empowerSegmentDescription = (segment) => {
          return `Empower your ${capitalize(
            segment
          )} learning journey with Generative AI and LLMs.`;
        };

        const segmentsWithDescriptions = {
          school: empowerSegmentDescription("School"),
          college: empowerSegmentDescription("College"),
          languages: empowerSegmentDescription("Languages"),
          references:
            "Get insights from our references and empower your learning journey with Generative AI and LLMs.",
        };

        for (const segment in segmentsWithDescriptions) {
          if (segments.includes(segment)) {
            newDescription += " " + segmentsWithDescriptions[segment];
          }
        }
      }
      setTitle(newTitle);
      setDescription(newDescription);
    }, [location]);

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
    );
  }

  if (!authContextValue) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <FaSpinner icon="spinner" className="animate-spin" size={50} />
      </div>
    );
  }

  function QuizRoute() {
    let { quizId } = useParams();
    if (quizId && quizId.match(/^\d{4,}$/)) {
      return <ShowPublicQuiz />;
    } else {
      return <CatchAll />;
    }
  }

  return (
    <div>
      <Router>
        <DynamicMetaTags />
        <AnalyticsLogger />
        {!isVideoEmbed && (
          <Navbar
            onLoginClick={handleLoginClick}
            handleSignOut={handleSignOut}
          />
        )}
        {showLogin && <Login closeLogin={() => setShowLogin(false)} />}
        {logoutNotification && (
          <div className="fixed top-0 right-0 bg-green-500 text-white px-4 py-2 rounded">
            Successfully logged out
          </div>
        )}
        {loginNotification && (
          <div className="fixed top-0 right-0 bg-green-500 text-white px-4 py-2 rounded">
            Successfully signed in
          </div>
        )}
        <div className="flex-grow w-full h-full">
          <div className=" w-full h-full flex-grow">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route exact path="/" element={<HomeLayout />} />
                <Route path="/:quizId" element={<QuizRoute />} />
                <Route path="/guide" element={<Guide />} />
                <Route path="/guide-md" element={<GuideMD />} />
                <Route path="/create" element={<Guide />} />
                <Route path="/myguide" element={<MyGuides />} />
                <Route path="/video" element={<ShowVideo />} />
                <Route path="/course" element={<Course />} />
                <Route path="/quiz" element={<Quiz />} />
                <Route path="/login" element={<Login />} />
                <Route path="/quiz/:quizId" element={<ShowPublicQuiz />} />
                <Route path="/tutorial" element={<TutorialCreate />} />
                <Route path="/lecture" element={<LectureCreate />} />
                <Route path="/go/:name" element={<GoRedirect />} />
                <Route path="/subscribe" element={<SubscribePage />} />
                <Route path="/my" element={<My />} />
                <Route path="/myquiz" element={<MyQuiz />} />
                <Route path="/family/:familyName" element={<Family />} />
                <Route path="/family" element={<Family />} />
                <Route path="/privacy" element={<PrivacyLayout />} />
                <Route path="/security" element={<PrivacyLayout />} />
                <Route path="/cookie" element={<PrivacyLayout />} />
                <Route path="/qr" element={<QRCodeGenerator />} />
                <Route path="/tc" element={<ThumbnailCreator />} />
                <Route path="/logo" element={<ThumbnailCreator />} />
                <Route path="/food" element={<Food />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/create-video-with-ai" element={<GuideMD />} />
                <Route path="/ai" element={<GuideMD />} />
                <Route path="/banner" element={<ThumbnailCreator />} />
                <Route
                  path="/privacy-security-cookie"
                  element={<PrivacyLayout />}
                />
                <Route path="*" element={<CatchAll />} />
              </Routes>
            </Suspense>
          </div>
        </div>
        {!isVideoEmbed && shouldShowFooter && <Footer />}

        {!isVideoEmbed && !shouldShowFooter && (
          <p className="text-center text-sm text-gray-500 my-6">
            Please email{" "}
            <a
              href="mailto:admin@kimavi.com"
              className="text-blue-500 underline"
            >
              admin@kimavi.com
            </a>{" "}
            for video creation support
          </p>
        )}
        {isVideoEmbed && (
          <div className="text-left p-2 text-xs">
            Powered by{" "}
            <a
              href="https://www.kimavi.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kimavi
            </a>
          </div>
        )}
      </Router>
    </div>
  );
}

export default App;
