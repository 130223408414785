// HomeLayout.js
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "./AuthProvider";
import Hero from "./components/Hero";
import FeaturedGuide from "./components/FeaturedGuide";
import Sidebar from "./components/Sidebar";
import PreviewGuide from "./PreviewGuide";
import { useNavigate } from "react-router-dom";
import useAppLocation from "./useAppLocation";

function HomeLayout() {
  const navigate = useNavigate();
  const { userEmail, planId, isPaid, isAuthChecking, idToken } =
    useContext(AuthContext);
  const { shouldRenderSidebar } = useAppLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedGuide, setSelectedGuide] = useState(null);
  const [guideData, setGuideData] = useState(null);
  const [isExploreTabActive, setIsExploreTabActive] = useState(false);

  // State for rotating messages
  const [learningMessageIndex, setLearningMessageIndex] = useState(0);
  const [videoMessageIndex, setVideoMessageIndex] = useState(0);

  // Arrays of messages to rotate
  const learningMessages = ["Edu Videos", "How-To Videos", "News Videos"];
  const videoMessages = ["Made Faster", "Made Better", "Made Cheaper"];

  // Effect to rotate learning messages every 3 seconds
  useEffect(() => {
    const learningInterval = setInterval(() => {
      setLearningMessageIndex(
        (prevIndex) => (prevIndex + 1) % learningMessages.length
      );
    }, 2000);
    return () => clearInterval(learningInterval);
  }, []);

  // Effect to rotate video messages every 3 seconds
  useEffect(() => {
    const videoInterval = setInterval(() => {
      setVideoMessageIndex(
        (prevIndex) => (prevIndex + 1) % videoMessages.length
      );
    }, 2000);
    return () => clearInterval(videoInterval);
  }, []);

  useEffect(() => {
    if (selectedGuide) {
      const { uid, recordingSessionId } = selectedGuide;
      navigate(`/video?id=${recordingSessionId}&source=server&uid=${uid}`);
    }
  }, [selectedGuide]);

  const handleTabChange = (tabIndex) => {
    setIsExploreTabActive(tabIndex === 1);
  };

  return (
    <div className="grid grid-cols-1 md:flex md:grid-cols-none h-full w-full">
      {/* Render the sidebar if it should be rendered and isSidebarOpen is true */}
      {shouldRenderSidebar && isSidebarOpen && (
        <Sidebar
          onGuideSelect={setSelectedGuide}
          onTabChange={handleTabChange}
        />
      )}

      <div className="flex flex-col m-2 p-2 w-full">
        {guideData && isExploreTabActive ? (
          <PreviewGuide guideData={guideData} />
        ) : (
          <>
            {/* Learning Section with Rotating Messages */}
            <section className="bg-gradient-to-b from-blue-50 to-gray-100 py-12">
              <div className="container mx-auto space-y-12 px-6 md:px-12">
                {/* Hero Section */}

                {/* Video Creation Section with Rotating Messages */}
                <div className="text-center ">
                  <h2 className="text-6xl font-bold text-gray-800 mb-8 ">
                    Create Video Tutorials with AI{" "}
                  </h2>
                  <div className="mb-4 ">
                    <span className="bg-black text-4xl text-[#00FFFF] px-3 py-2 rounded ">
                      {videoMessages[videoMessageIndex]}
                    </span>{" "}
                  </div>
                  <div className="bg-white rounded-lg shadow-lg p-2 hover:shadow-xl transition-shadow duration-300 mt-10">
                    <FeaturedGuide />
                  </div>
                </div>

                <div className="text-center">
                  <h2 className="text-6xl font-bold text-gray-800 mb-8">
                    Explore Amazing Videos{" "}
                  </h2>
                  <div className="mb-4">
                    <span className="bg-black text-3xl text-[#00FFFF] px-3 py-3 rounded">
                      {learningMessages[learningMessageIndex]}
                    </span>{" "}
                  </div>
                  <div className="mb-4">
                    <div className="bg-white rounded-lg shadow-lg p-2 hover:shadow-xl transition-shadow duration-300 mt-10">
                      <Hero />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </div>
  );
}

export default HomeLayout;
