import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RainbowButton1 } from "./Rainbow";
import {
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaPinterestP,
  FaEnvelope,
  FaApple,
  FaChrome,
  FaBook,
  FaInfinity,
  FaInstagram,
} from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";
import {
  MdArrowForwardIos,
  MdArrowBackIos,
  MdOutlineRefresh,
} from "react-icons/md"; // Imported MdOutlineRefresh icon
import { featuredGuide } from "../constants/featured";
import { FaGraduationCap, FaLightbulb } from "react-icons/fa";
import { RainbowButton } from "./Rainbow";
import { MdOutlineAutoAwesome } from "react-icons/md";

const iconStyle = { color: "#FFEB3B", marginRight: "8px" };
const redInfinityStyle = {
  color: "#FFEB3B",
  marginLeft: "8px",
  marginRight: "8px",
};

// Utility function to shuffle the array
function shuffleArray(array) {
  return array
    .map((item) => ({ ...item, sortKey: Math.random() })) // Add random sort key
    .sort((a, b) => a.sortKey - b.sortKey); // Sort by that key
}

function Footer() {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const guidesPerPage = 3; // Number of guides to display per page
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleGuides, setVisibleGuides] = useState([]);

  // Shuffle the guides and set visible guides on load
  useEffect(() => {
    const shuffledGuides = shuffleArray(featuredGuide); // Shuffle the guides on load
    setVisibleGuides(shuffledGuides.slice(0, guidesPerPage)); // Set the first `guidesPerPage` guides
  }, []); // Run only once on component mount

  // Shuffle guides on refresh button click
  const handleRefresh = () => {
    const shuffledGuides = shuffleArray(featuredGuide);
    setCurrentIndex(0); // Reset to the first page of guides
    setVisibleGuides(shuffledGuides.slice(0, guidesPerPage));
  };

  const handleGuideSelect = (guide) => {
    navigate(
      `/video?id=${guide.recordingSessionId}&source=server&uid=${guide.uid}`
    );
  };

  const handleNext = () => {
    const newIndex =
      currentIndex + guidesPerPage < featuredGuide.length
        ? currentIndex + guidesPerPage
        : currentIndex;
    setCurrentIndex(newIndex);
    setVisibleGuides(featuredGuide.slice(newIndex, newIndex + guidesPerPage));
  };

  const handlePrev = () => {
    const newIndex =
      currentIndex - guidesPerPage >= 0 ? currentIndex - guidesPerPage : 0;
    setCurrentIndex(newIndex);
    setVisibleGuides(featuredGuide.slice(newIndex, newIndex + guidesPerPage));
  };

  return (
    <footer className="bg-gray-100 py-8 flex-shrink-0">
      {/* Featured Guide Section */}
      <div className="container mx-auto px-4 text-center mb-6">
        <h2 className="text-xl font-bold text-gray-800 mb-4">
          Featured - How To Videos
        </h2>
        <div className="flex flex-col md:flex-row justify-between items-center">
          <button
            onClick={handlePrev}
            aria-label="Previous guides"
            disabled={currentIndex === 0}
            className={`rounded-full m-2 p-2 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ${
              currentIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <MdArrowBackIos className="text-gray-800" />
          </button>
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-4 overflow-hidden justify-center mb-4 md:mb-0">
            {visibleGuides.map((guide, index) => (
              <div
                key={index}
                className="max-w-md bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl mb-4 md:mb-0"
                onClick={() => handleGuideSelect(guide)}
              >
                <div className="p-8">
                  <a
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="block mt-1 text-lg leading-tight font-medium text-black hover:underline"
                  >
                    {guide.guideTitle}
                  </a>
                  <p className="mt-2 text-gray-500">#{guide.tags.join(" #")}</p>
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={handleNext}
            aria-label="Next guides"
            disabled={currentIndex + guidesPerPage >= featuredGuide.length}
            className={`rounded-full m-2 p-2 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ${
              currentIndex + guidesPerPage >= featuredGuide.length
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            <MdArrowForwardIos className="text-gray-800" />
          </button>
        </div>

        {/* Refresh Button Below the Row */}
        <div className="flex items-center justify-center mt-6">
          <button
            onClick={handleRefresh}
            className="bg-white p-3 rounded-full shadow-lg text-gray-800 hover:bg-gray-100 transition-transform transform hover:scale-110"
          >
            <MdOutlineRefresh size={30} />
          </button>
        </div>
      </div>

      {/* Footer Content */}
      <div className="container mx-auto px-4 text-center">
        <hr className="my-6 border-gray-700" />
        <div className="mb-3 flex space-x-4 justify-center">
          <a href="mailto:admin@kimavi.com" size="6">
            <FaEnvelope />
          </a>
          <a
            href="https://www.youtube.com/kimavi"
            target="_blank"
            rel="noopener noreferrer"
            size="6"
          >
            <FaYoutube />
          </a>
          <a
            href="https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne?hl=en&authuser=0&pli=1"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Kimavi on Chrome Web Store"
          >
            <FaChrome />
          </a>
        </div>

        <div className="flex flex-wrap items-center justify-center mb-4">
          <div className="w-full md:w-6/12 lg:w-4/12 px-4">
            <div className="text-xs sm:text-sm text-gray-400 font-semibold py-1">
              &copy; {currentYear} Kimavi. All rights reserved.
            </div>
            <div className="text-xs sm:text-sm text-gray-400 font-semibold py-1 mb-5 mt-5">
              AI Tools for Creation, Marketing and Monetization of Videos
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center mb-4">
          <div className="border p-2 rounded bg-gray-300">
            <Link to="/privacy-security-cookie">
              <span className="text-xs font-semibold text-gray-500 pb-2 pt-3">
                Terms of Use: Privacy, Security and Cookie Policy
              </span>
            </Link>
          </div>
        </div>

        <div className="flex flex-wrap items-center justify-center mb-4">
          <div className="w-full md:w-6/12 lg:w-4/12 px-4">
            <div className="text-xs sm:text-sm text-gray-400 font-semibold py-1">
              For Help and Support, Please Email{" "}
              <a href="mailto:admin@kimavi.com" className="text-gray-500">
                admin@kimavi.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
