import styled from "styled-components";
import { MdOutlineAutoAwesome } from "react-icons/md";
import { FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

// Styled component for the circle with icons and text
const CircleK = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-size: 200% 100%;
  background-image: linear-gradient(
    to right,
    #5f9ea0,
    #204051,
    #5f9ea0,
    #008080
  );
  animation: rainbow 20s ease infinite;
  color: #ffffff;
  border-radius: 50%; /* Full circle */
  width: 90px;
  height: 90px;
  text-align: center;
  font-weight: bold;
  padding: 5px; /* Adjust padding for smaller circle */

  @keyframes rainbow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const KimaviBrand = () => {
  return (
    <div className="flex justify-center items-center">
      <Link to="/" className="flex items-center">
        {/* Circle with icons and text */}
        <CircleK>
          {/* First icon at the top */}
          <MdOutlineAutoAwesome className="text-[#00FFFF] text-3xl mt-1" />

          {/* "Kimavi" in the middle */}
          <span className="text-white text-2xl">Kimavi</span>

          {/* Second icon at the bottom */}
          <FaYoutube className="text-[#00FFFF] text-2xl mb-2" />
        </CircleK>
      </Link>
    </div>
  );
};

export { KimaviBrand };
