import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  MdOutlineArrowBack,
  MdOutlineArrowForward,
  MdOutlineRefresh,
} from "react-icons/md";

// Course data
const courses = [
  {
    id: 1,
    title: "Chemical Engineering",
    image:
      "/tutorials/engineering/chemical-engineering/images/preface-and-introduction-to-chemical-engineering-abc101-portrait.jpeg",
    link: "/college/engineering/chemical-engineering",
  },
  {
    id: 2,
    title: "Civil Engineering",
    image:
      "/tutorials/engineering/civil-engineering/images/preface-and-introduction-to-civil-engineering-abc101-portrait.jpeg",
    link: "/college/engineering/civil-engineering",
  },
  {
    id: 3,
    title: "Computer Engineering",
    image:
      "/tutorials/engineering/computer-engineering/images/preface-and-introduction-to-computer-engineering-abc101-portrait.jpeg",
    link: "/college/engineering/computer-engineering",
  },
  {
    id: 4,
    title: "Electrical Engineering",
    image:
      "/tutorials/engineering/electrical-engineering/images/preface-and-introduction-to-electrical-engineering-abc101-portrait.jpeg",
    link: "/college/engineering/electrical-engineering",
  },
  {
    id: 5,
    title: "Mechanical Engineering",
    image:
      "/tutorials/engineering/mechanical-engineering/images/preface-and-introduction-to-mechanical-engineering-abc101-portrait.jpeg",
    link: "/college/engineering/mechanical-engineering",
  },
];

const Hero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCourses, setVisibleCourses] = useState([]);
  const navigate = useNavigate();

  // Handle forward and back navigation
  const goForward = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === courses.length - 3 ? 0 : prevIndex + 1
    );
  };

  const goBack = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? courses.length - 3 : prevIndex - 1
    );
  };

  // Set visible courses based on the current index
  useEffect(() => {
    setVisibleCourses(courses.slice(currentIndex, currentIndex + 3));
  }, [currentIndex]);

  // Shuffle courses
  const shuffleCourses = () => {
    const shuffled = [...courses].sort(() => Math.random() - 0.5);
    setVisibleCourses(shuffled.slice(0, 3));
  };

  return (
    <>
      <section className="bg-gray-50 text-gray-800 py-12 px-6 md:px-12">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8 mt-8 text-gray-400">
            Engineering - Made Easy
          </h2>
          <p className="m-2 p2">
            Videos and Quizzes Created with Generative AI
          </p>

          <div className="relative flex items-center justify-center">
            {/* Back Arrow */}
            <button
              className="absolute z-50 left-0 bg-white p-3 rounded-full shadow-lg text-gray-800 hover:bg-gray-100 transition-transform transform hover:scale-110"
              onClick={goBack}
            >
              <MdOutlineArrowBack size={30} />
            </button>

            {/* Courses Slider */}
            <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-1 lg:grid-cols-3 place-items-center w-full">
              {visibleCourses.map((course) => (
                <div
                  key={course.id}
                  className="w-full p-4 bg-gray-800 rounded-lg shadow-xl cursor-pointer hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
                  onClick={() => navigate(course.link)}
                >
                  {/* Lazy loaded image */}
                  <LazyImage
                    src={course.image}
                    alt={course.title}
                    className="w-full h-auto rounded-lg"
                  />
                  <h3 className="text-lg font-bold mt-4 text-white">
                    {course.title}
                  </h3>
                  <p className="text-md text-[#00FFFF] font-light">Made Easy</p>
                </div>
              ))}
            </div>

            {/* Forward Arrow */}
            <button
              className="absolute right-0 bg-white p-3 rounded-full shadow-lg text-gray-800 hover:bg-gray-100 transition-transform transform hover:scale-110"
              onClick={goForward}
            >
              <MdOutlineArrowForward size={30} />
            </button>
          </div>

          {/* Refresh Button */}
          <div className="flex items-center justify-center mt-6">
            <button
              className="bg-white p-3 rounded-full shadow-lg text-gray-800 hover:bg-gray-100 transition-transform transform hover:scale-110"
              onClick={shuffleCourses}
            >
              <MdOutlineRefresh size={30} />
            </button>
          </div>

          {/* Course Counter */}
          <div className="text-gray-800 mt-4">
            Showing {visibleCourses.length} of {courses.length} Courses
          </div>
        </div>
      </section>
    </>
  );
};

// LazyImage Component for Lazy Loading Images
const LazyImage = ({ src, alt }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Disconnect observer once the image is loaded
          }
        });
      },
      { threshold: 0.1 }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [imageRef]);

  return (
    <div
      ref={imageRef}
      className="h-48 bg-cover bg-center rounded-lg"
      style={{
        backgroundImage: isVisible
          ? `url(${src})`
          : "url('/placeholder-image-url.jpg')", // Placeholder before loading image
      }}
      alt={alt}
    ></div>
  );
};

export default Hero;
